/** ***************************************************************************
 * Template / Homepage PB
 *************************************************************************** */

window.addEventListener('lemuria:init', () => {
  function initialize() {
    const buttons = document.querySelectorAll('.lm-u-button');
    buttons.forEach(
      (button) =>
        (button.onmouseover = (event) => {
          const elementClientRect = button.getBoundingClientRect();
          const x = event.clientX - elementClientRect.left;
          const y = event.clientY - elementClientRect.top;
          button.style.setProperty('--data-before-left', x + 'px');
          button.style.setProperty('--data-before-top', y + 'px');
        })
    );
  }

  function destroy() {}

  window.LEMURIA.register('templates-homepage_pb', initialize, destroy);

  initialize();
});
